export interface FiltersInterface {
  sites_ids: FiltersItems;
  status?: FiltersItems;
  search_url?: string;
  search_email?: string;
  is_unsubscribed?: FiltersItems;
  show_all?: FiltersItems;
  show_unclaimed?: { id: string; value: boolean };
  in_edit_history?: FiltersItems;
  owner: FiltersItems;
  templates_ids: FiltersItems;
  affinities_ids: FiltersItems;
  audiences_ids: FiltersItems;
  show_cannibalization_attempts: FiltersItems;
  show_only_emails: FiltersItems;
  notify_opens: FiltersItems;
  min_mod_date: FiltersDates;
  max_mod_date: FiltersDates;
  min_added_date: FiltersDates;
  max_added_date: FiltersDates;
  min_found_date: FiltersDates;
  max_found_date: FiltersDates;
}

export interface FiltersAssignedInterface {
  status: FiltersItems;
  user_id: FiltersItems;
  site: FiltersItems;
  url: FiltersItems;
  affinity: FiltersItems;
  in_query: FiltersItems;
  audience: FiltersItems;
  template: FiltersItems;
  min_mod_date: FiltersDates;
  max_mod_date: FiltersDates;
  min_added_date: FiltersDates;
  max_added_date: FiltersDates;
}

export interface FiltersClaimsInterface {
  site: FiltersItems;
  owner: FiltersItems;
  manager: FiltersItems;
  url: FiltersItems;
  domain_authority: FiltersItems;
  domain_rating: FiltersItems;
  total_points: FiltersItems;
  status: FiltersItems;
  link_type: FiltersItems;
  found: FiltersDates;
  min_mod_date: FiltersDates;
  max_mod_date: FiltersDates;
  min_added_date: FiltersDates;
  max_added_date: FiltersDates;
  min_approved_date: FiltersDates;
  max_approved_date: FiltersDates;
  approved_by?: FiltersItems;
  show_hidden: { id: string; value: boolean };
}

export interface FiltersTemplateInterface {
  sites_ids: FiltersItems;
  status: FiltersItems;
  owner: FiltersItems;
  affinity: FiltersItems;
  audience: FiltersItems;
  min_added_date: FiltersDates;
  max_added_date: FiltersDates;
  min_mod_date: FiltersDates;
  max_mod_date: FiltersDates;
}

interface FiltersItems {
  id: string;
  value: string;
  disabled?: boolean;
}

interface FiltersDates {
  id: string;
  value: string | null;
}

export interface VisibleColumnsInterface {
  site__name: boolean;
  template__title: boolean;
  affinity__name: boolean;
  audience__name: boolean;
  created: boolean;
  modified: boolean;
  found_not_null: boolean;
  owner__username: boolean;
  status: boolean;
  notes_count: boolean;
  is_unsubscribed: boolean;
}

export const filtersAssigned = (id: number) => {
  return {
    status: { id: 'status', value: 'assigned' },
    user_id: { id: 'user_id', value: `${id}` },
    min_mod_date: { id: 'min_mod_date', value: null },
    max_mod_date: { id: 'max_mod_date', value: null },
    min_added_date: { id: 'min_added_date', value: null },
    max_added_date: { id: 'max_added_date', value: null },
    affinity: { id: 'affinity', value: '' },
    site: { id: 'site', value: '' },
    audience: { id: 'audience', value: '' },
    template: { id: 'template', value: '' },
    in_query: { id: 'in_query', value: '' },
    url: { id: 'url', value: '' },
  };
};

export const filtersObj = {
  sites_ids: { id: 'sites_ids', value: '' },
  templates_ids: { id: 'templates_ids', value: '' },
  affinities_ids: { id: 'affinities_ids', value: '' },
  audiences_ids: { id: 'audiences_ids', value: '' },
  search_url: '',
  search_email: '',
  status: { id: 'status', value: '' },
  show_cannibalization_attempts: {
    id: 'show_cannibalization_attempts',
    value: '',
  },
  show_only_emails: { id: 'show_only_emails', value: '' },
  notify_opens: { id: 'notify_opens', value: '' },
  show_all: { id: 'show_all', value: 'true' },
  owner: { id: 'owner', value: '' },
  min_mod_date: { id: 'min_mod_date', value: null },
  max_mod_date: { id: 'max_mod_date', value: null },
  min_added_date: { id: 'min_added_date', value: null },
  max_added_date: { id: 'max_added_date', value: null },
  min_found_date: { id: 'min_found_date', value: null },
  max_found_date: { id: 'max_found_date', value: null },
};

export const filtersObjWithDate = () => {
  return {
    sites_ids: { id: 'sites_ids', value: '' },
    show_cannibalization_attempts: {
      id: 'show_cannibalization_attempts',
      value: '',
    },
    show_all: {
      id: 'show_all',
      value: 'true',
    },
    show_only_emails: { id: 'show_only_emails', value: '' },
    notify_opens: { id: 'notify_opens', value: '' },
    is_unsubscribed: { id: 'is_unsubscribed', value: '' },
    templates_ids: { id: 'templates_ids', value: '' },
    affinities_ids: { id: 'affinities_ids', value: '' },
    audiences_ids: { id: 'audiences_ids', value: '' },
    status: { id: 'status', value: '' },
    owner: { id: 'owner', value: '' },
    search_url: '',
    search_email: '',
    min_mod_date: { id: 'min_mod_date', value: null },
    max_mod_date: { id: 'max_mod_date', value: null },
    min_added_date: { id: 'min_added_date', value: null },
    max_added_date: { id: 'max_added_date', value: null },
    min_found_date: { id: 'min_found_date', value: null },
    max_found_date: { id: 'max_found_date', value: null },
  };
};

export const filtersObjUnclaimed = {
  sites_ids: { id: 'sites_ids', value: '' },
  show_cannibalization_attempts: {
    id: 'show_cannibalization_attempts',
    value: '',
  },
  show_only_emails: { id: 'show_only_emails', value: '' },
  notify_opens: { id: 'notify_opens', value: '' },
  templates_ids: { id: 'templates_ids', value: '' },
  affinities_ids: { id: 'affinities_ids', value: '' },
  audiences_ids: { id: 'audiences_ids', value: '' },
  status: { id: 'status', value: 'active' },
  show_unclaimed: { id: 'show_unclaimed', value: true },
  owner: { id: 'owner', value: '' },
  min_mod_date: { id: 'min_mod_date', value: null },
  max_mod_date: { id: 'max_mod_date', value: null },
  min_added_date: { id: 'min_added_date', value: null },
  max_added_date: { id: 'max_added_date', value: null },
  min_found_date: { id: 'min_found_date', value: null },
  search_email: '',
  search_url: '',
  max_found_date: { id: 'max_found_date', value: null },
};

export const filtersObjValuesUnclaimed = (owner: number) => {
  return {
    sites_ids: { id: 'sites_ids', value: '' },
    templates_ids: { id: 'templates_ids', value: '' },
    affinities_ids: { id: 'affinities_ids', value: '' },
    search_url: '',
    show_cannibalization_attempts: {
      id: 'show_cannibalization_attempts',
      value: '',
    },
    show_only_emails: { id: 'show_only_emails', value: '' },
    notify_opens: { id: 'notify_opens', value: '' },
    search_email: '',
    audiences_ids: { id: 'audiences_ids', value: '' },
    status: { id: 'status', value: 'active' },
    show_unclaimed: { id: 'show_unclaimed', value: true },
    owner: { id: 'owner', value: `${owner}` },
    min_mod_date: { id: 'min_mod_date', value: null },
    max_mod_date: { id: 'max_mod_date', value: null },
    min_added_date: { id: 'min_added_date', value: null },
    max_added_date: { id: 'max_added_date', value: null },
    min_found_date: { id: 'min_found_date', value: null },
    max_found_date: { id: 'max_found_date', value: null },
  };
};

export const filtersObjClaims = {
  site: { id: 'site', value: '' },
  owner: { id: 'owner', value: '' },
  manager: { id: 'manager', value: '' },
  url: { id: 'url', value: '' },
  domain_authority: { id: 'domain_authority', value: '' },
  domain_rating: { id: 'domain_rating', value: '' },
  total_points: { id: 'total_points', value: '' },
  status: { id: 'status', value: '' },
  link_type: { id: 'link_type', value: '' },
  found: { id: 'found', value: null },
  min_mod_date: { id: 'min_mod_date', value: null },
  max_mod_date: { id: 'max_mod_date', value: null },
  min_added_date: { id: 'min_added_date', value: null },
  max_added_date: { id: 'max_added_date', value: null },
  min_approved_date: { id: 'min_approved_date', value: null },
  max_approved_date: { id: 'max_approved_date', value: null },
  show_hidden: { id: 'show_hidden', value: true },
};

export const filtersObjMaster = {
  site: { id: 'site', value: '' },
  owner: { id: 'owner', value: '' },
  manager: { id: 'manager', value: '' },
  url: { id: 'url', value: '' },
  domain_authority: { id: 'domain_authority', value: '' },
  domain_rating: { id: 'domain_rating', value: '' },
  total_points: { id: 'total_points', value: '' },
  status: { id: 'status', value: 'approved' },
  link_type: { id: 'link_type', value: '' },
  found: { id: 'found', value: null },
  min_mod_date: { id: 'min_mod_date', value: null },
  max_mod_date: { id: 'max_mod_date', value: null },
  min_added_date: { id: 'min_added_date', value: null },
  max_added_date: { id: 'max_added_date', value: null },
  min_approved_date: { id: 'min_approved_date', value: null },
  max_approved_date: { id: 'max_approved_date', value: null },
  show_hidden: { id: 'show_hidden', value: true },
  approved_by: { id: 'approved_by', value: '' },
};

export const filtersObjValuesWithOwner = (owner: number) => {
  return {
    site: { id: 'site', value: '' },
    owner: { id: 'owner', value: `${owner}` },
    manager: { id: 'manager', value: '' },
    url: { id: 'url', value: '' },
    domain_authority: { id: 'domain_authority', value: '' },
    domain_rating: { id: 'domain_rating', value: '' },
    total_points: { id: 'total_points', value: '' },
    status: { id: 'status', value: '' },
    link_type: { id: 'link_type', value: '' },
    found: { id: 'found', value: null },
    min_mod_date: { id: 'min_mod_date', value: null },
    max_mod_date: { id: 'max_mod_date', value: null },
    min_added_date: { id: 'min_added_date', value: null },
    max_added_date: { id: 'max_added_date', value: null },
    min_approved_date: { id: 'min_approved_date', value: null },
    max_approved_date: { id: 'max_approved_date', value: null },
    show_hidden: { id: 'show_hidden', value: true },
  };
};

export const filtersObjClaimsWithStatus = {
  site: { id: 'site', value: '' },
  owner: { id: 'owner', value: '' },
  manager: { id: 'manager', value: '' },
  url: { id: 'url', value: '' },
  domain_authority: { id: 'domain_authority', value: '' },
  domain_rating: { id: 'domain_rating', value: '' },
  total_points: { id: 'total_points', value: '' },
  status: { id: 'status', value: 'review' },
  link_type: { id: 'link_type', value: '' },
  found: { id: 'found', value: null },
  min_mod_date: { id: 'min_mod_date', value: null },
  max_mod_date: { id: 'max_mod_date', value: null },
  min_added_date: { id: 'min_added_date', value: null },
  max_added_date: { id: 'max_added_date', value: null },
  min_approved_date: { id: 'min_approved_date', value: null },
  max_approved_date: { id: 'max_approved_date', value: null },
  show_hidden: { id: 'show_hidden', value: true },
};

export const filtersObjTemplates = {
  sites_ids: { id: 'sites_ids', value: '' },
  status: { id: 'status', value: '' },
  affinity: { id: 'affinity', value: '' },
  audience: { id: 'audience', value: '' },
  owner: { id: 'owner', value: '' },
  min_added_date: { id: 'min_added_date', value: null },
  max_added_date: { id: 'max_added_date', value: null },
  min_mod_date: { id: 'min_mod_date', value: null },
  max_mod_date: { id: 'max_mod_date', value: null },
};

export type SearchTypeLinks = {
  search_url: string;
  exact_url: boolean;
  search_email: string;
  exact_email: boolean;
};
