/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { Box, Divider, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FiltersAccordion from '../../../components/Filters/FiltersAccordion';
import {
  filtersObjUnclaimed,
  filtersObjValuesUnclaimed,
  VisibleColumnsInterface,
} from '../../../models/Filters';
import DateFilters from '../../../components/Filters/DateFilters';
import { GlobalContextType, LoadingContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import LeftColumns from '../../../components/Columns/LeftColumns';
import SitesAccordion from '../../../components/Filters/SitesAccordion';
import AutoCompleteSelect from '../../../components/Filters/AutoCompleteSelect';
import { fetchTemplatesFilter } from '../../../helpers/templates';
import LoadingContext from '../../../context/LoadingContext';
import FavFilters from '../../../components/Filters/FavFilters/FavFilters';
import { BasicArray, MessagesInt } from '../../../models/General';
import FeedbackMessage from '../../../components/FeedbackMessage';
import { Owners, UserContextType } from '../../../models/User';
import { ProjectsRequest } from '../../../models/Projects';
import { DefaultCheckbox, SearchTextField } from '../../../components/inputs';
import Context from '../../../context/UserContext';
import { fetchSitesAudiencesAffinitiesMinimal } from '../../../helpers/sites';
import useFiltersUnclaimed from '../../../stores/filters/useFiltersUnclaimed';
import { FlexBox } from '../../../components/tableItems';
import { IS_USER_ADMIN } from '../../../constants/user';

interface Props {
  columnsVisibility: VisibleColumnsInterface;
  setColumnsVisible: React.Dispatch<
    React.SetStateAction<VisibleColumnsInterface>
  >;
  owners: Owners[];
  projects: ProjectsRequest[];
}

const filtersUnclaimedRoles = ['linkbuilder', 'contractor'];

const FiltersUnclaimed = ({
  columnsVisibility,
  setColumnsVisible,
  owners,
  projects,
}: Props) => {
  const filterUnclaimed = useFiltersUnclaimed();
  const [favFilterId, setFavFilterId] = useState(0);
  const { UserContext } = useContext(Context) as UserContextType;
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedback, setFeedback] = useState<MessagesInt>({
    message: '',
    severity: 'success',
  });
  const [clearAll, setClearAll] = useState(false);
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;

  const [templates, setTemplates] = useState<BasicArray[]>([]);
  const [affinities, setAffinities] = useState<BasicArray[]>([]);
  const [audience, setAudience] = useState<BasicArray[]>([]);
  const [render, setRender] = useState(0);
  const [disabledAddFav, setDisabledAddFav] = useState(true);

  // useEffect(() => {
  //   if (render !== 0 && !isLoadingContext.loading && tab.numberTab === 2) {
  //     let queryFinal = '';
  //     if (searchFiltersLinks.search_url !== '') {
  //       queryFinal += searchFiltersLinks.exact_url
  //         ? `&search_url_exact=${searchFiltersLinks.search_url}`
  //         : `&search_url=${searchFiltersLinks.search_url}`;
  //     }
  //     if (searchFiltersLinks.search_email !== '') {
  //       queryFinal += searchFiltersLinks.exact_email
  //         ? `&search_email_exact=${searchFiltersLinks.search_email}`
  //         : `&search_email=${searchFiltersLinks.search_email}`;
  //     }
  //     setQueryFinalSearch(queryFinal);
  //   }
  // }, [searchFiltersLinks]);

  const getQueryFinal = (search_url: string, search_email: string) => {
    let queryFinal = '';
    if (search_url !== '') {
      queryFinal += `&search_url=${search_url}`;
    }
    if (search_email !== '') {
      queryFinal += `&search_email=${search_email}`;
    }
    return queryFinal;
  };

  const createQuery = () => {
    const copyObj = { ...filterUnclaimed.filterValuesDefault };
    const queryFinal = getQueryFinal(
      copyObj.search_url ?? '',
      copyObj.search_email ?? '',
    );
    delete copyObj.search_email;
    delete copyObj.search_url;
    const copyFilters = JSON.parse(
      JSON.stringify(filterUnclaimed.filterValuesDefault),
    );
    copyFilters.sites_ids.value =
      copyFilters.sites_ids.value !== ''
        ? copyFilters.sites_ids.value.replaceAll(':', ',')
        : '';
    const ArrFilters: { id: string; value: string }[] = Object.assign(
      [],
      Object.values(copyFilters),
    );
    const arrayElements = ArrFilters.filter(item => item.value);
    if (arrayElements.length > 0) setDisabledAddFav(false);
    else setDisabledAddFav(true);
    const queryString = arrayElements.map(
      query => `&${query.id}=${query.value}`,
    );

    setGlobalContext({
      ...GlobalContext,
      links: { ...GlobalContext.links, order: '-created' },
      claims: { ...GlobalContext.claims, order: 'modified' },
      master: { ...GlobalContext.master, order: 'link' },
      unclaimed: {
        ...GlobalContext.unclaimed,
        page: 1,
        page_size: 10,
        query_filters: queryString.join(''),
        search_filter: queryFinal,
      },
    });
  };

  useEffect(() => {
    createQuery();
  }, [filterUnclaimed.filterValuesDefault]);

  useEffect(() => {
    const obj = filtersUnclaimedRoles.includes(UserContext.profile.role)
      ? filtersObjValuesUnclaimed(UserContext.id)
      : filtersObjUnclaimed;
    filterUnclaimed.updateFiltersChange(obj);
    filterUnclaimed.updateFiltersDefault(obj);
  }, []);

  const showMessage = (obj: MessagesInt) => {
    setFeedback(obj);
    setFeedbackOpen(true);
  };

  const updateFiltersValue = (id: string, value: string) => {
    let obj = { ...filterUnclaimed.filterValuesChange };
    if (id === 'sites_ids') {
      if (value !== '') {
        obj = {
          ...obj,
          sites_ids: { id, value },
          templates_ids: { ...obj.templates_ids, value: '' },
        };
        filterUnclaimed.updateFiltersChange(obj);
      }

      if (value === '') {
        obj = {
          ...obj,
          sites_ids: { id, value },
          templates_ids: { ...obj.templates_ids, value: '' },
          audiences_ids: { ...obj.audiences_ids, value: '' },
          affinities_ids: { ...obj.affinities_ids, value: '' },
        };
        filterUnclaimed.updateFiltersChange(obj);
      }
      return;
    }
    obj = { ...obj, [id]: { id, value } };
    filterUnclaimed.updateFiltersChange(obj);
  };

  const updateDates = (id: string, value: string | null) => {
    let obj = { ...filterUnclaimed.filterValuesChange };
    obj = { ...obj, [id]: { id, value } };
    filterUnclaimed.updateFiltersChange(obj);
  };

  const onEnterOption = () =>
    filterUnclaimed.updateFiltersDefault(filterUnclaimed.filterValuesChange);

  const filtersArray = [
    {
      id: 13,
      visibilityId: 'email_contacts',
      visible: undefined,
      countUse:
        filterUnclaimed.filterValuesChange.show_only_emails.value !== ''
          ? 1
          : 0,
      title: 'Email Contacts',
      body: (
        <>
          <FlexBox>
            <DefaultCheckbox
              size="small"
              onClick={() => {
                let obj = { ...filterUnclaimed.filterValuesChange };
                obj = {
                  ...obj,
                  show_only_emails: {
                    id: 'show_only_emails',
                    value:
                      filterUnclaimed.filterValuesChange.show_only_emails
                        .value === ''
                        ? 'true'
                        : '',
                  },
                };
                filterUnclaimed.updateFiltersChange(obj);
              }}
              checked={
                filterUnclaimed.filterValuesChange.show_only_emails.value !== ''
              }
            />
            <Typography fontSize={12}>
              Only entries with email contacts
            </Typography>
          </FlexBox>
          {IS_USER_ADMIN.includes(UserContext.profile.role) && (
            <FlexBox>
              <DefaultCheckbox
                size="small"
                onClick={() => {
                  let obj = { ...filterUnclaimed.filterValuesChange };
                  obj = {
                    ...obj,
                    notify_opens: {
                      id: 'notify_opens',
                      value:
                        filterUnclaimed.filterValuesChange.notify_opens
                          .value === ''
                          ? 'true'
                          : '',
                    },
                  };
                  filterUnclaimed.updateFiltersChange(obj);
                }}
                checked={
                  filterUnclaimed.filterValuesChange.notify_opens.value !== ''
                }
              />
              <Typography fontSize={12}>
                Only entries with opened emails
              </Typography>
            </FlexBox>
          )}
        </>
      ),
      disabled: false,
    },
    {
      id: 12,
      visibilityId: 'cannibalization_vis',
      visible: undefined,
      countUse:
        filterUnclaimed.filterValuesChange.show_cannibalization_attempts
          .value !== ''
          ? 1
          : 0,
      title: 'Cannibalization Attempts',
      body: (
        <FlexBox>
          <DefaultCheckbox
            size="small"
            onClick={() => {
              let obj = { ...filterUnclaimed.filterValuesChange };
              obj = {
                ...obj,
                show_cannibalization_attempts: {
                  id: 'show_cannibalization_attempts',
                  value:
                    filterUnclaimed.filterValuesChange
                      .show_cannibalization_attempts.value === ''
                      ? 'true'
                      : '',
                },
              };
              filterUnclaimed.updateFiltersChange(obj);
            }}
            checked={
              filterUnclaimed.filterValuesChange.show_cannibalization_attempts
                .value !== ''
            }
          />
          <Typography fontSize={12}>Show Cannibalization Attempts</Typography>
        </FlexBox>
      ),
      disabled: false,
    },
    {
      id: 2,
      visibilityId: 'site__name',
      title: 'Site',
      countUse:
        filterUnclaimed.filterValuesChange.sites_ids.value !== ''
          ? filterUnclaimed.filterValuesChange.sites_ids.value
              .split(',')
              .map(item => item.split(':'))
              .map(e => e.map(Number))
              .flat().length
          : 0,
      visible: columnsVisibility.site__name,
      body: (
        <SitesAccordion
          favFilters={0}
          projectsArr={projects}
          value={filterUnclaimed.filterValuesChange.sites_ids.value}
          updateFiltersValue={updateFiltersValue}
          clearAll={clearAll}
        />
      ),
      disabled: !(projects.length > 0),
    },

    {
      id: 4,
      visible: columnsVisibility.audience__name,
      countUse:
        filterUnclaimed.filterValuesChange.audiences_ids.value !== ''
          ? filterUnclaimed.filterValuesChange.audiences_ids.value.split(',')
              .length
          : 0,
      visibilityId: 'audience__name',
      title: 'Audience',
      body: (
        <AutoCompleteSelect
          disabledInput={isLoadingContext.loading}
          options={audience}
          placeholder="Audience"
          updateFiltersValue={updateFiltersValue}
          clearAll={clearAll}
          onEnter={onEnterOption}
          value={audience.filter(item => {
            const id = item.id as number;
            return (
              filterUnclaimed.filterValuesChange.audiences_ids.value
                .split(',')
                .indexOf(id.toString()) !== -1
            );
          })}
          id={filterUnclaimed.filterValuesChange.audiences_ids.id}
        />
      ),
      disabled: !(audience.length > 0),
    },
    {
      id: 3,
      title: 'Affinity',
      countUse:
        filterUnclaimed.filterValuesChange.affinities_ids.value !== ''
          ? filterUnclaimed.filterValuesChange.affinities_ids.value.split(',')
              .length
          : 0,
      visibilityId: 'affinity__name',
      visible: columnsVisibility.affinity__name,
      body: (
        <AutoCompleteSelect
          disabledInput={isLoadingContext.loading}
          clearAll={clearAll}
          options={affinities}
          onEnter={onEnterOption}
          placeholder="Affinity"
          value={affinities.filter(item => {
            const id = item.id as number;
            return (
              filterUnclaimed.filterValuesChange.affinities_ids.value
                .split(',')
                .indexOf(id.toString()) !== -1
            );
          })}
          updateFiltersValue={updateFiltersValue}
          id={filterUnclaimed.filterValuesChange.affinities_ids.id}
        />
      ),
      disabled: !(affinities.length > 0),
    },
    {
      id: 6,
      visibilityId: 'template__title',
      visible: columnsVisibility.template__title,
      countUse:
        filterUnclaimed.filterValuesChange.templates_ids.value !== ''
          ? filterUnclaimed.filterValuesChange.templates_ids.value.split(',')
              .length
          : 0,
      title: 'Template',
      body: (
        <AutoCompleteSelect
          clearAll={clearAll}
          options={templates}
          placeholder="Template"
          onEnter={onEnterOption}
          value={templates.filter(item => {
            const id = item.id as number;
            return (
              filterUnclaimed.filterValuesChange.templates_ids.value
                .split(',')
                .indexOf(id.toString()) !== -1
            );
          })}
          id={filterUnclaimed.filterValuesChange.templates_ids.id}
          disabledInput={isLoadingContext.loading}
          updateFiltersValue={updateFiltersValue}
        />
      ),
      disabled: !(templates.length > 0),
    },
    {
      id: 5,
      visibilityId: 'owner__username',
      title: 'Owner',
      countUse:
        filterUnclaimed.filterValuesChange.owner.value !== ''
          ? filterUnclaimed.filterValuesChange.owner.value.split(',').length
          : 0,
      visible: columnsVisibility.owner__username,
      body: (
        <AutoCompleteSelect
          disabledInput={isLoadingContext.loading}
          options={owners.map(item => {
            return { id: item.id, name: item.username };
          })}
          clearAll={clearAll}
          placeholder="Owner"
          onEnter={onEnterOption}
          updateFiltersValue={updateFiltersValue}
          value={owners
            .map(item => {
              return { id: item.id, name: item.username };
            })
            .filter(item => {
              const id = item.id as number;
              return (
                filterUnclaimed.filterValuesChange.owner.value
                  .split(',')
                  .indexOf(id.toString()) !== -1
              );
            })}
          id={filterUnclaimed.filterValuesChange.owner.id}
        />
      ),
      disabled: !(owners.length > 0),
    },
    {
      id: 7,
      visibilityId: 'created',
      title: 'Added Date',
      visible: columnsVisibility.created,
      countUse:
        filterUnclaimed.filterValuesChange.min_added_date.value &&
        filterUnclaimed.filterValuesChange.max_added_date.value
          ? 2
          : filterUnclaimed.filterValuesChange.max_added_date.value ||
            filterUnclaimed.filterValuesChange.min_added_date.value
          ? 1
          : 0,
      body: (
        <DateFilters
          onEnter={onEnterOption}
          disableInput={isLoadingContext.loading}
          min_id={filterUnclaimed.filterValuesChange.min_added_date.id}
          min_value={filterUnclaimed.filterValuesChange.min_added_date.value}
          max_id={filterUnclaimed.filterValuesChange.max_added_date.id}
          max_value={filterUnclaimed.filterValuesChange.max_added_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
    {
      id: 8,
      visibilityId: 'modified',
      title: 'Updated Date',
      visible: columnsVisibility.modified,
      countUse:
        filterUnclaimed.filterValuesChange.min_mod_date.value &&
        filterUnclaimed.filterValuesChange.max_mod_date.value
          ? 2
          : filterUnclaimed.filterValuesChange.max_mod_date.value ||
            filterUnclaimed.filterValuesChange.min_mod_date.value
          ? 1
          : 0,
      body: (
        <DateFilters
          min_id={filterUnclaimed.filterValuesChange.min_mod_date.id}
          min_value={filterUnclaimed.filterValuesChange.min_mod_date.value}
          disableInput={isLoadingContext.loading}
          max_id={filterUnclaimed.filterValuesChange.max_mod_date.id}
          max_value={filterUnclaimed.filterValuesChange.max_mod_date.value}
          onEnter={onEnterOption}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
    {
      id: 9,
      visibilityId: 'found_not_null',
      visible: columnsVisibility.found_not_null,
      title: 'Found Date',
      countUse:
        filterUnclaimed.filterValuesChange.min_found_date.value &&
        filterUnclaimed.filterValuesChange.max_found_date.value
          ? 2
          : filterUnclaimed.filterValuesChange.max_found_date.value ||
            filterUnclaimed.filterValuesChange.min_found_date.value
          ? 1
          : 0,
      body: (
        <DateFilters
          min_id={filterUnclaimed.filterValuesChange.min_found_date.id}
          disableInput={isLoadingContext.loading}
          min_value={filterUnclaimed.filterValuesChange.min_found_date.value}
          max_id={filterUnclaimed.filterValuesChange.max_found_date.id}
          max_value={filterUnclaimed.filterValuesChange.max_found_date.value}
          onEnter={onEnterOption}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
  ];

  const getTemplateInfo = async () => {
    try {
      const resp = await fetchTemplatesFilter(
        filterUnclaimed.filterValuesChange.audiences_ids.value,
        filterUnclaimed.filterValuesChange.affinities_ids.value,
      );
      const templatesTransform = resp
        .filter(item => item.status === 'active')
        .map(temp => {
          return { id: temp.id, name: temp.title };
        });
      setTemplates(
        templatesTransform.sort((a, b) => (a.name < b.name ? -1 : 1)),
      );
    } catch (err) {
      setTemplates([]);
    }
  };

  useEffect(() => {
    if (render !== 0) {
      if (
        filterUnclaimed.filterValuesChange.affinities_ids.value !== '' &&
        filterUnclaimed.filterValuesChange.audiences_ids.value !== ''
      ) {
        getTemplateInfo();
      }
    }
  }, [
    filterUnclaimed.filterValuesChange.affinities_ids,
    filterUnclaimed.filterValuesChange.audiences_ids,
  ]);

  const responseAudAff = async (site_ids: string) => {
    try {
      const resp = await fetchSitesAudiencesAffinitiesMinimal(site_ids);
      const aud = resp.map(item => item.audiences);
      const aff = resp.map(item => item.affinities);
      return { aud: aud.flat(), aff: aff.flat() };
    } catch (err) {
      return { aud: [], aff: [] };
    }
  };

  const getAudAff = async (site_ids: string) => {
    setTemplates([]);
    if (site_ids === '') return;
    const transformSiteIds = site_ids.replaceAll(':', ',');
    const resp = await responseAudAff(transformSiteIds);
    setAffinities(resp.aff);
    setAudience(resp.aud);
  };

  useEffect(() => {
    if (render !== 0) {
      if (filterUnclaimed.filterValuesChange.sites_ids.value !== '') {
        setAudience([]);
        setAffinities([]);
        getAudAff(filterUnclaimed.filterValuesChange.sites_ids.value);
      } else {
        setTemplates([]);
        setAudience([]);
        setAffinities([]);
      }
    }
  }, [filterUnclaimed.filterValuesChange.sites_ids]);

  useEffect(() => {
    setRender(1);
  }, []);

  const updateFavFilterId = (id: number) => setFavFilterId(id);
  return (
    <LeftColumns>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 3,
        }}
      >
        <Typography sx={{ fontWeight: 900, fontSize: 16 }}>Filters</Typography>
        <Typography
          onClick={() => {
            if (isLoadingContext.loading) return;
            const obj = filtersUnclaimedRoles.includes(UserContext.profile.role)
              ? filtersObjValuesUnclaimed(UserContext.id)
              : filtersObjUnclaimed;
            filterUnclaimed.updateFiltersChange(obj);
            filterUnclaimed.updateFiltersDefault(obj);

            setTimeout(() => {
              setClearAll(!clearAll);
            }, 700);
          }}
          sx={{
            fontSize: 14,
            cursor: 'pointer',
            ':hover': {
              color: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          Clear All
        </Typography>
      </Box>

      <Divider sx={{ border: '1px solid rgba(0, 0, 0, 0.06)' }} />
      <Box paddingTop={3} paddingBottom={1} paddingX={3}>
        <Typography
          fontSize={12}
          color="#000000"
          fontWeight={700}
          marginBottom={1.3}
        >
          Search by Contact Email
        </Typography>

        <SearchTextField
          disabled={isLoadingContext.loading}
          value={filterUnclaimed.filterValuesChange.search_email}
          onChange={e => {
            filterUnclaimed.updateFiltersChange({
              ...filterUnclaimed.filterValuesChange,
              search_email: e.target.value,
            });
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              filterUnclaimed.updateFiltersDefault(
                filterUnclaimed.filterValuesChange,
              );
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          size="small"
          fullWidth
          type="text"
          placeholder="Contact Email"
          id="search_email"
        />
        {/* <FormControlLabel
          control={
            <DefaultCheckbox
              size="small"
              disabled={isLoadingContext.loading}
              checked={searchFiltersLinks.exact_email}
              onChange={e =>
                onChangeSearchLinksChecks('exact_email', e.target.checked)
              }
            />
          }
          label="Exact Search"
          labelPlacement="end"
          sx={{
            '& .MuiFormControlLabel-label': { fontSize: 12 },
            marginTop: 0.5,
          }}
        /> */}
      </Box>
      <Box marginTop={2.4}>
        <FavFilters
          disabledAdd={disabledAddFav}
          setClearAll={setClearAll}
          favFilterId={favFilterId}
          updateFavFilterId={updateFavFilterId}
          clearAll={clearAll}
          showMessage={showMessage}
        />
        {filtersArray.map(filter => (
          <FiltersAccordion
            key={filter.id}
            title={filter.title}
            visible={filter.visible}
            countUse={filter.countUse}
            body={filter.body}
            disabled={filter.disabled}
            visibilityId={filter.visibilityId}
            columnsVisibility={columnsVisibility}
            setColumnsVisible={setColumnsVisible}
          />
        ))}
      </Box>
      <FeedbackMessage
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        vertical="top"
        horizontal="left"
        severity={feedback.severity}
        message={feedback.message}
      />
    </LeftColumns>
  );
};

export default FiltersUnclaimed;
